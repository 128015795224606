import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import type { MapStats } from "@/game-destiny2/components/Profile/types.js";
import calcKDA from "@/shared-fps/calc-kda.mjs";
import HighlightStatCard from "@/shared-fps/HighlightStatCard.jsx";
import TimescaleChart from "@/shared-fps/TimescaleChart.jsx";

export default memo(function MapsTopStatCards({
  maps,
  profileImgUrl,
}: {
  maps: Array<[string, MapStats]>;
  profileImgUrl: string;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const bestStat = maps
    .slice()
    .sort(([, aStat], [, bStat]) => bStat.wins - aStat.wins)?.[0];
  if (!bestStat) return null;
  const highlight = {
    label:
      bestStat?.[0] ?? t("destiny2:suggestion.noBestMap", "No best played map"),
    position: bestStat?.[0] ? 1 : 0,
    stat: (bestStat?.[1].wins ?? 0).toLocaleString(language, {
      maximumFractionDigits: 0,
    }),
    statLabel: t("common:wins", "Wins"),
    image: profileImgUrl,
    imageSize: 100,
  };
  const mapSortedByKills = maps
    .slice()
    .sort((a, b) => b[1].kills - b[1].kills)
    .slice(0, 10);
  const timeScaleData = mapSortedByKills.map((map) => {
    const stats = map[1];
    const kills = stats.kills;
    const kda = calcKDA(kills, stats.deaths, stats.assists, 1);
    const pkda = calcKDA(stats.precisionKills, stats.deaths, stats.assists, 1);
    return {
      key: map[0],
      tooltipTitle: <div>{map[0]}</div>,
      stats: {
        kills: {
          value: kills,
          display: kills.toLocaleString(language),
        },
        assists: {
          value: stats.assists,
          display: stats.assists.toLocaleString(language),
        },
        deaths: {
          value: stats.deaths,
          display: stats.deaths.toLocaleString(language),
        },
        precisionKills: {
          value: stats.precisionKills,
          display: stats.precisionKills.toLocaleString(language),
        },
        secondsPlayed: {
          value: stats.secondsPlayed,
          display: stats.secondsPlayed,
        },
        wins: {
          value: stats.precisionKills,
          display: stats.precisionKills.toLocaleString(language),
        },
        kda: {
          value: kda,
          display: kda.toLocaleString(language),
        },
        pkda: { value: pkda, display: pkda.toLocaleString(language) },
      },
    };
  });
  const kda = {
    id: "kda",
    label: ["common:stats.kda", "KDA"] as Translation,
    position: 0,
    stat1Label: ["common:stats.kda", "KDA"] as Translation,
    stat2Label: ["common:precisionKDA", "Precision KDA"] as Translation,
    color: "var(--red)",
    data: timeScaleData,
    xField: "secondsPlayed",
    yField: "kda",
    y2Field: "pkda",
    hidePosition: true,
  };
  const kills = {
    id: "kills",
    label: ["common:stats.kills", "Kills"] as Translation,
    position: 0,
    stat1Label: ["common:stats.kills", "Kills"] as Translation,
    stat2Label: ["common:precisionKills", "Precision Kills"] as Translation,
    color: "var(--orange)",
    data: timeScaleData,
    xField: "secondsPlayed",
    yField: "precisionKills",
    y2Field: "kills",
    hidePosition: true,
  };
  return (
    <GridContainer>
      {React.createElement(HighlightStatCard, highlight)}
      {React.createElement(TimescaleChart, kda)}
      {React.createElement(TimescaleChart, kills)}
    </GridContainer>
  );
});

// The avatar image needs hard-coded magic numbers to be aligned properly
const GridContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--sp-4);
  .avatar-container .avatar {
    border-radius: 100%;
    width: 66px;
    top: -7px;
    z-index: -1;
  }
`;
